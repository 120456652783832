export const emailValidation = (value: string) => {
    let regex = /^[A-Za-z0-9_!#$%&'*+\/=?`{|}~^.-]+@[A-Za-z0-9.-]+$/gm

    if (value.match(regex)) {
        return true
    } else {
        return false
    }
}

export const phoneValidation = (value: string) => {
   if (value.length === 14) {
        return true
    } else {
        return false
    }
}

export const setPhoneMask = (input: any) => {
    input.value = input.value
        .replace(/\D/g, '')
        .replace(/^(\d)/, '($1')
        .replace(/^(\(\d{3})(\d)/, '$1) $2')
        .replace(/(\d{3})(\d{1,5})/, '$1-$2')
        .replace(/(-\d{4})\d+?$/, '$1');
}

export const send_conversion_event = (hotjar= false, name: string, data:any) => {
    if (localStorage.getItem("test_mode")) {
        console.log(`tracking events: conversion - data:\n ${data}`);
        return false;
    }

    if (data && typeof window !== 'undefined') {
        try {
            // @ts-ignore
            jpnt_event && jpnt_event.log('conversion', name, JSON.parse(data));
        } catch (e) {
        }

        try {
            let id = data.case_id ? data.case_id : typeof (data) === 'string' ? JSON.parse(data).case_id : data.case_id

            // @ts-ignore
            window.dataLayer.push({
                "event": "conversion",
                "case_id": id,
                "value": data.value
            })

        } catch (e) {
        }

        if (hotjar) {
            try {
                // @ts-ignore
                hj('event', "conversion");
            } catch (e) {
            }
        }
    }
}

export const send_tracking_events = (type: string, value: string, data: any) => {
    if (localStorage.getItem("test_mode")) {
        try {
            console.log(`TRACKING= \ntype: ${type}, \nvalue: ${value}, \ndata: ${JSON.stringify(data)}`)
        }catch (e) {
        }

        return false;
    }

    try {
        // @ts-ignore
        jpnt_event && jpnt_event.log(
            type,
            value,
            JSON.parse(JSON.stringify(data)));
    } catch (e) {
    }
}

const getParam = (param: string) => {
    const match = RegExp('[?&]' + param + '=([^&]*)').exec(
        window.location.search
    )
    return match && decodeURIComponent(match[1].replace(/\+/g, ' '));
}

export const setAnalyticsFieldValue = (fieldName: string) => {
    const value = getParam(fieldName);

    return value ? value : "";
}

export const setUserId = () => {
    const user_id = localStorage.getItem('jpnt_event_user_id');

    return user_id ? user_id : "";
}

export const setAnalyticsFormFields = () => {
    return {
        'gclid': setAnalyticsFieldValue('gclid'),
        'gbraid': setAnalyticsFieldValue('gbraid'),
        'wbraid': setAnalyticsFieldValue('wbraid'),
        'user_id': setUserId(),
        'page_url': window.location.href
    }
}

