const step_1 = {
    'question': 'What type of cancer were you diagnosed with?',
    'description': '',
    'step_name': 'cancer_type',
    'answers': [
        {
            type: 'multioption',
            name: 'cancer_type',
            required: true,
            fields: [
                {
                    'text': 'Colorectal cancer',
                    'type': 'radio',
                    'tooltip': '',
                    'required': false
                },
                {
                    'text': 'Kidney (renal) cancer',
                    'type': 'radio',
                    'tooltip': '',
                    'required': false
                },
                {
                    'text': 'Liver cancer',
                    'type': 'radio',
                    'tooltip': '',
                    'required': false
                },
                {
                    'text': 'Esophageal cancer',
                    'type': 'radio',
                    'tooltip': '',
                    'required': false
                },
                {
                    'text': 'Pancreatic cancer',
                    'type': 'radio',
                    'tooltip': '',
                    'required': false
                },
                {
                    'text': 'Bone marrow cancer (plasma cell myeloma)',
                    'type': 'radio',
                    'tooltip': '',
                    'required': false
                },
                {
                    'text': 'Other',
                    'type': 'radio_with_text',
                    'tooltip': '',
                    'required': false
                }
            ]
        }
    ]
}

const step_2 = {
    'question': 'How has this condition impacted your life?',
    'description': 'Please select all that apply',
    'type': 'checkbox',
    'step_name': 'damage',
    'answers': [
        {
            type: 'multioption',
            name: 'damage_type',
            required: true,
            fields: [
                {
                    'text': 'Medical expenses',
                    'type': 'checkbox',
                    'tooltip': 'Costs incurred from the treatment of a condition, injury, or disease.',
                    'required': false
                },
                {
                    'text': 'Loss of income',
                    'type': 'checkbox',
                    'tooltip': 'Financial income lost due to a medical condition, injury, or disease.',
                    'required': false
                },
                {
                    'text': 'Permanent injury',
                    'type': 'checkbox',
                    'tooltip': 'An irreversible injury that has a serious & lasting effect on a person for the rest of their life.',
                    'required': false
                },
                {
                    'text': 'Death',
                    'type': 'checkbox',
                    'tooltip': 'Death caused by a condition, injury, or disease.',
                    'required': false
                },
                {
                    'text': 'Emotional trauma',
                    'type': 'checkbox',
                    'tooltip': 'Lasting emotional damage or harm caused by a condition, injury, or disease.',
                    'required': false
                },
                {
                    'text': 'Other',
                    'type': 'checkbox_with_text',
                    'tooltip': '',
                    'required': false
                },
            ]
        }
    ]
}

const step_3 = {
    'question': 'Please list all medications the patient has taken continuously for 6 months or more <span>(optional)</span>',
    'description': 'Taking certain medications for 6 months continuously can increase your risk of cancer',
    'step_name': 'medications',
    'answers': [
        {
            'text': 'Type medications',
            'type': 'textarea',
            'name': 'medications',
            'tooltip': '',
            'required': false
        }
    ]
}

const step_4 = {
    'question': 'Have you ever served in the military or lived on or near a military base?',
    'description': 'Military personnel are more likely to be exposed to chemicals known to cause cancer',
    'step_name': 'military',
    'answers': [
        {
            'type': 'multioption',
            'name': 'military',
            'tooltip': '',
            'required': true,
            fields: [
                {
                    text: 'Yes',
                    type: 'radio',
                    tooltip: '',
                    required: false
                },
                {
                    text: 'No',
                    type: 'radio',
                    tooltip: '',
                    required: false
                }
            ]
        },
    ]
}

const step_5 = {
    'question': 'Please list your current and past occupations <span>(optional)</span>',
    'description': 'Some jobs are more likely to expose you to chemicals known to cause cancer',
    'step_name': 'occupations',
    'answers': [
        {
            'text': 'Type occupations',
            'type': 'textarea',
            'name': 'occupations',
            'tooltip': '',
            'required': false
        }
    ]
}

const step_6 = {
    'question': 'Please add any additional information you think will help us better understand your case <span>(optional)</span>',
    'description': '',
    'type': 'text',
    'step_name': 'additional_information',
    'answers': [
        {
            'text': 'Type additional information',
            'name': 'additional_information',
            'type': 'textarea',
            'tooltip': '',
            'required': false
        }
    ]
}

const step_7 = {
    'question': 'Please enter your contact details',
    'description': '',
    'step_name': 'contact',
    'answers': [
        {
            'text': 'Name',
            'type': 'text',
            'name': 'name',
            'tooltip': '',
            'required': true
        },
        {
            'text': 'Phone number',
            'type': 'phone',
            'name': 'phone',
            'tooltip': '',
            'required': true
        },
        {
            'text': 'Email address',
            'type': 'email',
            'name': 'email',
            'tooltip': '',
            'required': true
        }
    ]
}



let steps = [step_1, step_2, step_3, step_4, step_5, step_6, step_7]

export const CONFIG_STEPS = steps

export const ADDITIONAL_RULES = (formData: any) => {
    if (formData) {
        console.log('formJSON - config ', formData)
    }
}


// const contactQuestionNode = new QuestionNode(contactQuestion, (answers) => null)
//     const descriptionQuestionNode = new QuestionNode(descriptionQuestion, (answers) => contactQuestionNode)
//     const truvadaQuestionNode = new QuestionNode(truvadaQuestion, (answers) => contactQuestionNode)
//     const cancerQuestionNode = new QuestionNode(cancerQuestion, (answers) => {
//         const lastAnswer = answers[answers.length - 1]
//         if (lastAnswer == 'lung cancer') {
//             return truvadaQuestionNode
//         }
//         return contactQuestionNode
//     })
//     const damagesQuestionNode = new QuestionNode(damagesQuestion, (answers) => {
//         const lastAnswer = answers[answers.length - 1]
//         if (lastAnswer == 'no') {
//             return descriptionQuestionNode
//         }
//         return cancerQuestionNode
//     })

// const startForm = () => {
//     let questions = []
//     let answers = []
//     let currentQuestionNode = damagesQuestionNode
//     do {
//         let content = currentQuestionNode.question.content
//         let choices = currentQuestionNode.question.choices
//         questions.push(currentQuestionNode.question.slug)
//         let answer
//         if (choices.length > 0) {
//             alert(content)
//             answer = prompt(choices)
//         } else {
//             answer = prompt(content)
//         }
//         answers.push(answer)
//         currentQuestionNode = currentQuestionNode.nextQuestionNode(answers)
//     } while(currentQuestionNode != null)
//     for (let i = 0; i < questions.length; i++) {
//         console.log(questions[i], ':', answers[i])
//     }
// }
