import styled from '@emotion/styled'
import { Fonts } from './_variables'


export const StepContent = styled.section`
    &.multistep {
        background: rgba(0,0,0,0.8);
        height: 100vh;
        left: 0;
        opacity: 1;
        position: fixed;
        top: 0;
        width: 100vw;        
        z-index: 10000010000;
    }
    
    .multistep-modal {
        background-color: #fff;
        font-family: ${Fonts.AkkuratFont};
        height: 100vh;
        left: 0;
        opacity: 1;
        position: fixed;
        top: 0;
        width: 100vw;
        
        @media (min-width: 1200px) {
            border-radius: 20px;
            height: 682px;
            left: 50%;
            top: 50px;
            width: 864px;
            transform: translate(-50%, 0);
        }
    }
    
    .modal-step {
        display: flex;
        flex-direction: column;
        gap: 8px;
    }
    
    .step__close {
        background-color: #FFF;
        border-radius: 999px;
        border: none;
        color: #A3A3A3;
        font-size: 10px;
        padding: 8px 16px;
        position: absolute;
        right: 2px;
        top: 0;
        
        @media (min-width: 1200px) {
            display: block;
            right: 8px;
            top: 8px;
        }
        
        &:hover {
            color: #000;
        }
    }
    
    .multistep-form {
        background-color: #fff;
        color: #000;
        display: flex;
        flex-direction: column;
        font-family: ${Fonts.AkkuratFont};
        gap: 8px;          
        padding: 0;
        
        @media (min-width: 1200px) {
            gap: 0;            
        }
    }
    
    .step__header {
        align-items: center;
        border-bottom: 1px solid #E5E5E5;
        display: flex;
        flex-direction: column;
        padding: 16px 24px;
        
        @media (min-width: 1200px) {
            padding: 24px;
        }
    }
    
    .step__progress-bar {
        align-self: flex-start;
        background-color: #DBE7E4;
        border-radius: 4px;
        height: 8px;
        width: calc(100% - 56px);
        
        .progress-bar {
            background-color: #8ABEB0;
            border-radius: 4px;
            transition-duration: 0.5s;
            transition-timing-function: ease-in;
        }
    }
    
    .step__content {
        display: flex;
        flex: 1 0 auto;
        flex-direction: column;
        gap: 8px;
        max-height: calc(100vh - 128px);
        overflow: scroll;
        padding: 16px 24px;
        
        @media (min-height: 800px) {
            max-height: calc(100vh - 180px);
        }
        
        @media (min-width: 1200px) {
            max-height: none;
            overflow: inherit;
            padding: 56px 80px;
            
        }
    }
    
    .modal-step:not(.modal-step--first) {
        @media (max-width: 1199px) {
            animation: fadeIn 0.3s, drawerstep 0.5s cubic-bezier(0.37, 0, 0.63, 1) forwards;
            
            @keyframes fadeIn {
                0% { opacity: 0; }                
                100%   { opacity: 1; }
            }

            @keyframes drawerstep {
                0% {
                    transform: translateY(10px);
                }
                100% {
                    transform: translateY(0);
                }
            }
        }
        
        &.step--hidden {
            display: none;
        }
        
        &.step--active {
            @media (max-width: 1199px) {
                animation: fadeOut 0.3s;
                
                @keyframes fadeOut {
                    0% { opacity: 1; }                
                    100%   { opacity: 0; }
                }
            }
        }
    }
    
    .step__question {
        font-size: 18px;
        font-weight: 700;
        line-height: 22px;
        margin-bottom: 0;
        text-align: center;
        
        span {
            color: #A3A3A3;
        }
        
        @media (min-width: 1200px) {
            font-size: 18px;
            line-height: 24px;
            margin: 0 auto;
        }
    }
    
    .step__description {
        color: #737373;
        font-size: 14px;
        line-height: 20px;
        margin-bottom: 0;
        text-align: center;
    }
    
    .step__form {        
        display: flex;
        flex-direction: column;
        gap: 8px;
        margin-top: 16px;
        
        @media (min-width: 1200px) {  
            align-items: center;  
            gap: 24px;        
            margin-top: 40px;
            
            &.step__form--wrap {
                flex-direction: row;
                flex-wrap: wrap;
                gap: 16px;
                
                .form-text,
                .form-check {
                    align-self: flex-start;
                    
                    &.form--other {
                        flex-direction: row;
                        
                        input[type="text"] {
                            margin-top: 16px;
                            padding: 6px 16px;
                        }
                    }
                }
            }
        }
        
        .form__field {
            cursor: pointer;
        }
        
        .field__tooltip {
            color: #D4D4D4;
            opacity: 1;
            position: absolute;
            right: 16px;
            top: 10px;
            
            @media (min-width: 1200px) {
                right: 24px;
                top: 24px;
            }
        }
        
        .form-control {
            border: 2px solid #E5E5E5;
            border-radius: 8px;
            font-size: 16px;
            margin-top: 0;
            padding: 10px 16px;
            
            @media (min-width: 1200px) {
                padding: 24px 16px;
                width: 420px;
            }
        }
        
        .form-check {            
            display: flex;
            padding: 0;   
            position: relative;
            width: 100%;
            
            @media (min-width: 1200px) {
                width: 341px;
            }
                        
            label {
                border: 2px solid #E5E5E5;
                border-radius: 8px;
                color: #000;
                cursor: pointer;
                flex: 1;
                font-size: 16px;
                font-weight: 400;
                letter-spacing: 0;
                line-height: 24px;
                margin-bottom: 0;
                padding: 10px 16px 10px 40px;
                text-transform: none;
                
                @media (min-width: 1200px) {
                    padding: 24px 16px 24px 40px;
                }
            }
        }
        
        .form-check-input {
            border-width: 2px;
            left: 40px;
            position: absolute;
            top: 12px;
            
            @media (min-width: 1200px) {
                top: 25px;
            }
            
            &:checked {
                background-color: #000;
                border-color: #000; 
                
                & + label {
                    background-color: #F5F5F5;
                    border-color: #000; 
                }  
                
                &:focus {
                    border-color: #000;
                }  
            }
            
            &:focus {
                border-color: #dee2e6;
                box-shadow: none;
            }
            
            &:active {
                background-color: #000;
                border-color: #000;
            }
        }
        
        .form-text,
        .form-textarea {
            color: #000;            
            
            &::-webkit-input-placeholder {
              color: #737373;
            }

            &:-ms-input-placeholder { 
              color: #737373;
            }

            &::placeholder {
              color: #737373;
            }
            
            &:checked {
                border-color: #000;
            }
            
            &:active {
                border-color: #000;
                box-shadow: none;
            }
        }
        
        .form-floating label {
            display: none;
        }
        
        .form-textarea {            
            @media (min-width: 1200px) {
                height: 172px;
                width: 500px;
            }
        }
        
        .input-group {
            @media (min-width: 1200px) {
                width: 420px;
            }
            
            &.active-field {
                .input-group-text {
                    border-color: #000;
                }
            }
            
            &.field--error {
                .input-group-text {
                    background-color: #FAE9F1;
                    border-color: #CC3665;
                    
                    svg {
                        color: #CC3665;
                    }
                }
                
                .form-text {
                    border-color: #CC3665;
                }
            }
        }
        
        .input-group-text {
            border-right: none;
            border-width: 2px;
            height: 48px;
            width: 48px;
            
            @media (min-width: 1200px) {
                height: 76px;
            }
            
            svg {
                color: #737373;
            }
            
            & + .form-control {
                border-left: none;
                
                @media (min-width: 1200px) {
                    width: 370px;
                }
            }
        }
        
        .form-text.active-field,
        .form-text.checked-field,
        .form-textarea.active-field,
        .form-textarea.checked-field {
            background-color: #F5F5F5;
            border-color: #000;
            
            &:focus {
                background-color: #F5F5F5;
                box-shadow: none;
            }
            
            &:active {
                background-color: #F5F5F5;
                box-shadow: none;
            } 
        }
        
        .form--other {
            border: 2px solid #E5E5E5;
            border-radius: 8px;
            flex-direction: column;
            
            .form-check-input {
                left: 38px;
                top: 10px;
                
                @media (min-width: 1200px) {
                    top: 24px;
                }
            }
            
            label {
                border: none;
                border-radius: 8px 8px 0 0;
                
                @media (min-width: 1200px) {
                    padding-bottom: 10px;
                    padding-top: 24px;
                }
            }
            
            input[type="text"] {
                background-color: #fff !important;
                border-color: #E5E5E5;
                margin: 5px 14px 16px;
                width: calc(100% - 28px);
            }
        }
        
        .form--other-checked {
            background-color: #F5F5F5;
            border-color: #000;
        }
    }
    
    .step__footer {
        background: rgba(255, 255, 255, 0.80);        
        backdrop-filter: blur(4px);
        border-top: 1px solid #E5E5E5;
        bottom: 0;
        display: flex;
        height: 88px;
        gap: 8px;
        justify-content: space-between;
        padding: 16px 24px;
        position: fixed;
        width: 100%;
        
        @media (min-width: 1200px) {
            border-radius: 0 0 20px 20px;
        }
        
        button {
            align-items: center;
            background-color: #000;
            border: none;
            border-radius: 999px;
            color: #fff;
            display: flex;
            font-size: 18px;
            gap: 8px;
            height: 56px;
            justify-content: center;
            width: 100%; 
        }
        
        .step__back {
            background-color: #f5f5f5;
            color: #000;
            width: 56px;
            
            &:hover {
                background-color: #e5e5e5;
            }
        }
        
        .step__next {
            @media (min-width: 1200px) {
                width: 192px;
            }
        }
        
        .step__next,
        .step__start {
            svg {
                transition: transform 0.3s;
            }
            
            &:hover {
                background-color: #262626;
                color: #fff;
            }
        }
    }
    
    .step__footer--intro {
            align-items: center;
            border-top: none;
            flex-direction: column;
            gap: 16px;
            height: 136px;
            
            @media (min-width: 1200px) {
                padding-bottom: 44px;
            }
            
            button {
                @media (min-width: 1200px) {
                    margin: 0 auto;
                    width: 340px;
                }
            }
        }
        
        .step__disclaimer {
            color: #737373;
            font-size: 12px;
            line-height: 16px;
            margin-bottom: 0;
            text-align: center;
            
            @media (min-width: 1200px) {
                max-width: 340px;
            }
            
            a {
                color: #737373;
                display: inline;
            }
        }
    
    .modal-step--first {
        &.step--hidden {
            display: none;
        }
        
        .step__close {
            box-shadow: 0px 16px 16px 0px rgba(0, 0, 0, 0.08);
            color: #000;
            right: 16px;
            top: 16px;
            
            @media (min-width: 1200px) {
                background-color: #F5F5F5;
                box-shadow: none;
            }
            
            &:hover {
                background-color: #e5e5e5;
            }
        }
        
        .step__content {
            align-items: center;
            padding: 92px 40px;
        }
        
        .step__intro {
            font-family: ${Fonts.VictorFont};
            font-size: 24px;
            line-height: 28px;   
            margin-bottom: 40px;         
            text-align: center;
            
            strong {
                font-family: ${Fonts.VictorItalicFont};
                font-style: italic;
            }
            
            @media (min-width: 1200px) {
                font-size: 32px;
                line-height: 40px;
                max-width: 80%;
            }
        }
        
        .step__image {
            display: none;
            
            @media (min-width: 1200px) {
                display: block;
                margin-bottom: 32px;
            }
        }
        
        .step__points {
            display: flex;
            flex-direction: column;
            gap: 24px;
        }
        
        .step__point {
            align-items: center;
            display: flex;
            gap: 24px;
            
            svg {
                color: rgba(98, 159, 145, 1);
                height: 32px;
                width: 32px;
            }
            
            p {
                color: #737373;
                margin-bottom: 0;
                
                strong {
                    color: #000;
                }
            }
        }
    }
    
    .modal-step--confirmation {
        background-color: #DBE7E4;
        
         @media (min-width: 1200px) {
            border-radius: 20px;
            height: 100%;
         }
        
        .step__close {
            box-shadow: 0px 16px 16px 0px rgba(0, 0, 0, 0.08);
            color: #000;
            right: 16px;
            top: 16px;
            z-index: 10000010000;
            
            &:hover {
                background-color: #e5e5e5;
            }
        }
        
        .step__content {
            max-height: 100vh;
            padding: 0;
            
            @media (min-width: 1200px) {
                padding: 40px 108px;
            }
        }
        
        .confirmation__intro {
            align-items: center;            
            display: flex;
            flex-direction: column;
            gap: 24px;
            padding: 32px 24px;
            
            @media (min-width: 1200px) {
                padding: 24px 108px;
            }
        }
        
        .intro__icon {
            align-items: center;
            background-color: #000;
            border-radius: 50%;
            color: #fff;
            display: flex;
            height: 40px;
            justify-content: center;
            width: 40px;
        }
        
        .intro__title {
            font-family: ${Fonts.VictorFont};
            font-size: 24px;
            line-height: 28px;
            margin-bottom: 0;
            text-align: center;
            
            @media (min-width: 1200px) {
                font-size: 32px;
                line-height: 40px;
            }
            
            span {
                color: #376A5F;
                font-family: ${Fonts.VictorItalicFont};
                font-style: italic;
            }
        }
        
        .intro__description {
            color: #737373;
            font-size: 14px;
            line-height: 20px;
            margin-bottom: 0;
            text-align: center;
            
            @media (min-width: 1200px) {
                max-width: 460px;
            }
        }
            
        .confirmation__steps {
            background-color: #fff;
            border-radius: 24px 24px 0 0;
            display: flex;
            flex-direction: column;
            gap: 40px 0;
            padding: 32px 24px;
            
            @media (min-width: 1200px) {
                border-radius: 24px;
                gap: 0;
                margin: 8px auto 0;
                width: 648px;
            }
        }
        
        .confirmation__title {
            font-size: 16px;
            font-weight: 700;
            line-height: 20px;
            margin-bottom: 0;
            text-align: center;
            
            @media (min-width: 1200px) {
                margin-bottom: 24px;
            }
        }
        
        .confirmation__content {
            display: flex;
            flex-direction: column;
            gap: 24px 0;
        }
    
        .confirmation__step {
            display: flex;
            font-family: ${Fonts.AkkuratFont};
            gap: 0 24px;
        }        
        
        .step__progress {
            position: relative;
        }
    
        .step__icon {
            align-items: center;
            background-color: #E5E5E5;
            border-radius: 50%;
            display: flex;
            height: 40px;
            justify-content: center;
            width: 40px;
            
            svg {
                height: 20px;
                width: 20px;
            }
        }
    
        .step__line {
            border: 1px dashed #D4D4D4;
            height: calc(100% - 75px);
            left: 18px;
            position: absolute;
            top: 50px;
            
            @media (min-width: 1200px) {
                display: none;
            }
        
            &::after {
                border: solid #D4D4D4;
                border-width: 0 2px 2px 0;
                bottom: 0;
                content: '';
                display: inline-block;
                left: -3px;
                padding: 2px;
                position: absolute;
                transform: rotate(45deg);
                -webkit-transform: rotate(45deg);
            }
        }
    
        .step__p {
            color: #737373;
            font-size: 14px;
            line-height: 20px;            
            
            span {
                color: #000;
                font-weight: 700;
            }
        }
    }
    
    .multistep-modal--tooltip {
        background: rgba(0,0,0,0.8);
        backdrop-filter: blur(8px);
        font-family: ${Fonts.AkkuratFont};
        height: 100vh;
        left: 0;
        opacity: 1;
        position: fixed;
        top: 0;
        width: 100vw;
        z-index: 10000010001;
    }
    
    .multistep-modal--tooltip__close {
        background-color: #F5F5F5;
        border: none;
        border-radius: 999px;
        color: #000;
        height: 40px;
        position: absolute;
        right: 16px;
        top: 16px;
        width: 56px;
    }
    
    .multistep-modal--tooltip__content {
        background-color: #fff;
        border-radius: 24px 24px 0 0;
        bottom: 0;
        height: 332px;
        padding: 126px 28px 110px;
        position: absolute;
        width: 100%;
        
        animation: drawer 0.3s cubic-bezier(0.37, 0, 0.63, 1) forwards;
        
        @keyframes drawer {
            0% {
                transform: translateY(100%);
            }
            100% {
                transform: translateY(0);
            }
        }
        
        &.tooltip--hidden {
            animation: drawer-out 0.3s cubic-bezier(0.37, 0, 0.63, 1) forwards;

            @keyframes drawer-out {
                0% {
                    transform: translateY(0);
                }
                100% {
                    transform: translateY(100%);
                }
            }
        }
        
        .content__title {
            font-size: 24px;
            line-height: 32px;
            margin-bottom: 24px;
            text-align: center;
        }
        
        .content__description {
            font-size: 14px;
            line-height: 20px;
            margin-bottom: 0;            
            text-align: center;
        }
    }
    
    .error_content {
        border-radius: 16px;
        border: 2px solid #F5C5DA;
        background: #FFF;
        box-shadow: 0px 16px 16px 0px rgba(0, 0, 0, 0.08), 0px 2px 4px 0px rgba(0, 0, 0, 0.10);
        color: #922240;
        display: flex;
        gap: 8px;
        margin: 16px auto 24px;
        padding: 12px 16px;
        width: fit-content;
        
        svg {
            color: #CC3665;
        }
    }
    
    &.hidden {
        display: none;
    }
    
    .hidden {
        display: none !important;
    }
    
    .popover {
        background-color: #262626;
        border-radius: 8px;
        font-family: ${Fonts.AkkuratFont};
        
        .popover-body {
            color: #fff;
            font-size: 12px;
            line-height: 16px;
            padding: 12px;
            
            p {
            }
            
            p: first-child {
                font-weight: 700;
            }
        }
        
        .popover-arrow::before,
        .popover-arrow::after {
            border-bottom-color: #262626;
        }
    }
    
`
